@import "common.css";

@font-face {
    font-family: LondonBetween;
    src: url(../fonts/LondonBetween.ttf);
}

@font-face {
    font-family: "Poppins";
	src: url(v2/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-Light";
	src: url(v2/fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins-SemiBold";
	src: url(v2/fonts/Poppins-SemiBold.ttf) format('truetype');
}


body {
	font-weight: 600;
	font-size: 14px!important;
	position:relative;
	font-family: 'Poppins-Light', Verdana, sans-serif;
    line-height: 1.5;
	background-color: #333333;
    padding: 5% 0;
}

.btn-payment,
.btn-continue {
    display: block;
    width: calc(100% - 40px);
    margin-left: 20px;
    background-color: #333333;
    color: white;
    padding: 10px;
    font-size: 18px;
    border-radius: 2px;
    border: 0;
    line-height: normal;
}
.title {
	padding-top: 15px;
	padding-bottom: 15px;
}
.title h2{
	margin:0!important;
	text-transform: uppercase;
	font-family: "Poppins-SemiBold", Verdana, sans-serif;
}
#benefits {
	background-color:#1090b1;
	display:block;
	color:white;
	padding: 30px 30px 60px 30px;
	background-image: url('images/choose-plan-bg.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

#benefits .title{
	margin-bottom: 20px!important;
}

#benefits  img {
	height:40px;
}

#benefits .benefit{
	margin-bottom:30px;
}
#benefits strong {
	text-transform: uppercase;
	display:block;
	padding-top:15px;
	padding-bottom: 15px;
	font-size: 18px;
}
.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
}

.radio .cr {
    border-radius: 50%;
}

.radio, .checkbox{
	margin-bottom: 0;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

.glyphicon-ok:before{
	color: #1090b1;
}



#your-plan {
	background-color:#f1f2f2;
	padding:15px;
}
.tab-panel .tab-content {
	margin-top:-5px;
}


.tab-panel {
	background-color:#F6F6F6;
}
.tab-panel .bordered {
	background-color: white;
}
.tab-panel hr {
	margin: 20px auto;
}


.plans {
	flex-direction: column;
	float: left;
	width: 33.3%;
	padding: 8px;
}

.plans .description {
	text-transform:uppercase;
	font-weight:600;
	font-size:20px;
	padding-top:30px!important;
}
.plans .information {
	list-style-type: none;
	border: 1px solid #eee;
	margin: 0;
	padding: 0;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	min-height: 375px;
	height:auto;
	position:relative;
}

.plans .information:hover,
#most-popular .information{
	box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

.plans .information .header {
	color: white!important;
	font-size: 1.9rem;
	font-weight: 600;
	padding: 15px;
}

.plans .information li {
	padding: 15px 20px;
	text-align: center;
	color:#5b5b5b!important;
	font-size:1.8rem;
	font-weight: 600;
}

.plans .information li:last-of-type {
	margin-top: auto;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.plans .checkbox label,
.plans .checkbox{
	margin:0;
	padding:0;
}

.plans .checkbox span.cr {
	color:#59c7c8!important;
}
.plans .price{
	font-size: 5rem!important;
	padding: 0!important;
}

.plans .price span:first-of-type{
	font-size: 2.2rem;
	vertical-align: middle;
}

.btn-payment {
	border: none;
	color: white;
	padding: 10px 25px;
	text-align: center;
	text-decoration: none;
	font-size: 18px;
	border-radius:2px;
}

.plans .header {
	position: relative;
	background: #88b7d5;
}
.plans .header:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #88b7d5;
	border-width: 18px;
	margin-left: -18px;
}

.plans .btn-payment {
	margin-top: 0!important;
	margin-bottom: 20px!important;
}

#most-popular .header,
#most-popular .btn-payment{
	background-color:#24bfd6!important;
}

#most-popular .header:after{
	border-top-color:#24bfd6;

}

#best-deals .header,
#best-deals .btn-payment{
	background-color:#fcd04b!important;
}

#best-deals .header:after{
	border-top-color:#fcd04b;
}

#standard li:nth-child(4){
	padding:10px 10px 10px 25px!important;
}

#standard .header,
#standard .btn-payment{
	background-color:#bbbbbb!important;
}

#standard .header:after{
	border-top-color:#bbbbbb;
}

#standard .information,
#best-deals .information{
	box-shadow: 0 1px 12px 0 rgba(0,0,0,0.2);
}

.modal-body .reactivation{
	color: gray;
}

.modal-body .reactivation a:not(.btn-continue) {
	color:inherit!important;
	font-size:inherit!important;
	font-weight: 600;
}
.reactivation .bordered {
	padding-top:60px;
	padding-bottom:60px;
}
.reactivation .uppercase {
	text-transform: uppercase;
}

.reactivation hr {
	margin-top: 15px;
	margin-bottom: 20px;
}

.reactivation img {
	display: block;
	margin: 0 auto;
	max-width: 75px;
	margin-bottom: 15px;
}

.reactivation a {
		color:#3ec3d8!important;
	}

.reactivation a:hover {
	text-decoration: underline;
	color:inherit;
}
.reactivation .btn-continue{
	margin-bottom: 30px;
	color:white!important;
	background-color:#6ed2e2!important;
}

.reactivation .sec-message{
	line-height: 1.6;
	color: #5b5b5b!important;
	padding: 5px 20px 15px 20px;
}

.reactivation .sec-message strong {
	font-weight: 600;
}
.reactivation .uppercase{
	font-size: 1.6rem;
	font-weight: 600;
	margin-bottom: 10px;
	display: block;
}

@media only screen and (max-width: 600px) {
	.plans{
		width: 100%;
	}
	#register-modal .modal-dialog,
	#message .modal-dialog {
		width: unset;
	}
}


.tab-panel .bordered {
	background-color: white;
}

#text {
	color: white;
}
