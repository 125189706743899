#header #currencies span,
#header #languages-list span,
#header #signin span,
#header #register span,
#header #user span,
#header #buy-card span{
    display: inline-block;
    border-radius: 5rem;
    line-height: 2rem;
    width: 2.9rem;
    text-align: center;
    padding: 0.5rem 0px;
	height: 2.9rem;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	vertical-align: middle;
}

#header #currencies span{
	color: #465664;
    background-color: #d3d6da;
}

#header #currencies span,
#header #languages span{
	font-family: 'Poppins-SemiBold', Verdana, sans-serif;
	font-weight: 600;
}

#header #currencies ul {
	text-align: right;
}
#header #languages-list {
	background-image: thin-arrow-bottom;
}
#header #languages-list span.all {
	background-image: url('v2/images/languages/language-icon.png');
}

#header #buy-card span{
	background-image:  url('v2/images/buy-card-icon.png');
}

#header #languages-list span.all,
#header #buy-card span, 
#header #register span {
	background-position: center center;
    background-size: contain;
    border-radius: 0;
    margin-right: 5px;
}

#header #signin span,
#header #user span {
	margin-right: 0.5rem;
	background-image:  url('v2/images/user-icon.png')
}

#header #user img{
	margin-right: 0.5rem;
}
#header #register span {
	margin-right: 0.5rem;
	background-image: url('v2/images/register-icon.png');
}

#header #currencies span.usd:before {
	content: "\0024";
}
#header #currencies span.eur:before {
	content: "\20AC";
}
#header #currencies span.gbp:before {
	content: "\00A3";
}
#header #currencies span.mxn:before {
	content: "MXN";
	font-size: 0.8em;
    padding: 0;
}
#header #currencies ul {
    min-width: 19rem;
    width: 100%;
}

#header #currencies ul, 
#header #user ul {
	margin:0;
	border:0;
	padding:0;
} 

#header #user img {
	max-width: 2.9rem;
	border-radius: 10rem;
	border:1px solid white;
}


@media (max-width: 1200px) {
	.nav>li a{
		padding: 8px 20px;
		text-align: left!important;
		font-size:18px;
		border-top: 1px solid white!important;
		text-transform: uppercase;
	}
	
	.nav>li:nth-child(1) a, 
	.nav>li:nth-child(4) a{
		background-color: #a4ce3a!important;
	}
	
	.nav>li:nth-child(2) a, 
	.nav>li:nth-child(5) a{
		background-color: #529ad3!important;
		
	}
	
	.nav>li:nth-child(3) a, 
	.nav>li:nth-child(6) button{
		background-color: #ea732d!important;
	}

	button#change-language {
		font-size: initial;
	}
}